<template>

  <main style="background-color: #F0F9EB;">
    <article v-loading="isSubmitting">
      <section>
        <div class="enquiry_a1">
          <div class="container">
            <div class="row" style="margin-top:40px">
              <div class="col-md-6">
                <h1 style="font-size: 2em; font-weight: 600; color: #233A2E;
  text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3), 0px -4px 10px rgba(255, 255, 255, 0.3);">Create your own dream with Ihamba Adventures</h1>
                <p class="fsp">
                  <span>We would love to hear from you!</span><br />
                  <span>
                    In order to provide you with the best suitable itinerary,
                    please fill in this form.
                  </span>
                </p>
              </div>
            </div>
            <div>
              <!-- <p class="fs5">Travel Information</p> -->

              <el-form
                :model="enquiryFormData"
                :rules="rules"
                label-position="top"
                ref="enquiryFormData"
                label-width="120px"
                class="demo-enquiryFormData"
              >
               
                <el-form-item>
                    <a href="http://www.safariably.com">
                  <el-button
                    style="background-color: #996727; color: white;"
                   
                  >Book a complementary safari planning session <i class="el-icon-d-arrow-right"></i></el-button>
                  </a>
                </el-form-item>
              </el-form>

            </div>
          </div>
        </div>
      </section>
    </article>
  </main>
</template>

<script>
export default {
  data() {
    return {
      isSubmitting: false,
      countries_list: ["Uganda", "Kenya", "Tanzania", "Rwanda"],
      accommodation_types: ["Budget", "Middle Class", "Luxury"],
      budgets: [
        { name: "$0.00 - $1000", value: "$0.00 - $1000" },
        { name: "$1000 - $2000", value: "$1000 - $2000" },
        { name: "$2000 - $3000", value: "$2000 - $3000" },
        { name: "$3000 - $4000", value: "$3000 - $4000" },
        { name: "Not Important", value: "not_important" },
      ],
      interests: [
        "Birding",
        "Wildlife",
        "Primates(Gorillas & Chimpanzees)",
        "Mountaineering",
        "Culture",
      ],
      enquiryFormData: {
        name: "",
        nationality: "",
        email: "",
        country: [],
        travel_period: "",
        number_of_days: "",
        number_of_persons: "",
        accommodation: "",
        budget_indication: "",
        interests: [],
        comments: "",
      },

      rules: {
        name: [
          {
            required: true,
            message: "Full Name is required",
            trigger: "blur",
          },
        ],
        nationality: [
          {
            required: true,
            message: "Nationality is required",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "Email is required",
            trigger: "blur",
          },
        ],
        country: [
          {
            required: true,
            message: "Country of destination is required",
            trigger: "change",
          },
        ],
        travel_period: [
          {
            required: true,
            message: "Travel Period is required",
            trigger: "change",
          },
        ],
        number_of_days: [
          {
            required: true,
            message: "Number of days are required",
            trigger: "blur",
          },
        ],
        number_of_persons: [
          {
            required: true,
            message: "Number of Persons are required",
            trigger: "blur",
          },
        ],
        accommodation: [
          {
            required: true,
            message: "accommodation is required",
            trigger: "blur",
          },
        ],
        budget_indication: [
          {
            required: true,
            message: "budget_indication is required",
            trigger: "change",
          },
        ],
        interests: [
          {
            required: true,
            message: "interests are required",
            trigger: "change",
          },
        ],
        comments: [
          {
            required: true,
            message: "Comment is required",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.isSubmitting = true;
            let request = await this.$http.post(
              `api/enquiry/add`,
              this.enquiryFormData
            );
            if (
              request.data.success &&
              request.data.message == "ENQUIRY_ADDED_SUCCESSFULLY"
            ) {
              this.$notify({
                title: "Success",
                message: "Tailor Made Safari Submitted Successfully",
                type: "success",
              });
            } else throw "UNEXPECTED_RESPONSE";
          } catch (error) {
            if (error.message == "NetworkError") {
              this.isSubmitting = false;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred please try again",
                type: "warning",
              });
            }
            this.isSubmitting = false;
            this.$notify({
              title: "Submission Failed",
              message: "Unable to Submit Tailor Made Safari, Please try again",
              type: "error",
            });
          } finally {
            this.isSubmitting = false;
          }
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
.enquiry_a1 {
  padding: 60px 0px 100px 0px;
}
label {
  margin-top: 20px;
  color: rgb(83, 82, 82);
}
</style>